import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const documentApiRoutes = {
    lineSchedule: "document/line/",
    lineScheduleDownload: "document/download/line/",
};

export class DocumentAPI {
    static getLineScheduleDocument(lineId) {
        const url = `${documentApiRoutes.lineSchedule}${lineId}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static downloadLineScheduleDocument(document) {
        const url = `${documentApiRoutes.lineScheduleDownload}${document}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        }, false);
    }
}