import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const providerApiRoutes = {
    defaultRoute: "provider/"
};

export class ProviderAPI {
    static getProviders() {
        const url = `${providerApiRoutes.defaultRoute}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getProviderLines(providerName) {
        const url = `${providerApiRoutes.defaultRoute}${providerName}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}