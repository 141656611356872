import { DocumentAPI } from '../../api/DocumentAPI';

export class DocumentCommand {
    getLineScheduleDocument(lineId, successCallback, errorCallback) {
        const apiResult = DocumentAPI.getLineScheduleDocument(lineId);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    downloadLineScheduleDocument(document, successCallback, errorCallback) {
        const apiResult = DocumentAPI.downloadLineScheduleDocument(document);
        apiResult.then(async (result) => {
            if (undefined !== successCallback) {
                successCallback(await result.blob());
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}