import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Brand } from './Brand';

export class Layout extends Component {
    displayName = Layout.name

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false
        };
    }

    toggleMenuHandler() {
        const { isMenuOpen } = this.state;

        this.setState({
            isMenuOpen: !isMenuOpen
        });
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Row className="no-padding">
                        <Col className="no-padding">
                            {this.props.children}
                            <Brand />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
