import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { ProviderCommand } from '../commands/ProviderCommand';
import { ScheduleCommand } from '../commands/ScheduleCommand';
import { TripCommand } from '../commands/TripCommand';
import { DocumentCommand } from '../commands/DocumentCommand';

export class Schedules extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "schedules";

        this.state = {
            commands: {
                provider: new ProviderCommand(),
                schedule: new ScheduleCommand(),
                trip: new TripCommand(),
                document: new DocumentCommand()
            },
            data: {
                scheduleInfo: {
                    stops: [],
                    trips: [],
                    exceptions: []
                },
                document: undefined
            },
            dependencies: {
                providers: [],
                lines: [],
                dayTypes: []
            },
            form: {
                provider: undefined,
                line: undefined,
                direction: undefined,
                dayType: undefined
            },
            render: {
                lineDropdown: false,
                directionDropdown: false,
                dayTypeDropdown: false,
                disableDownloadButton: true
            },
            actions: {
                isLoadingProviders: false,
                isLoadingLines: false,
                isLoadingDayTypes: false,
                isLoadingInfo: false
            }
        };
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            this.getProviderLines({
                id: 1,
                name: "SMTUC",
                transportType: "Autocarros"
            });
        } else {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;

        setState(this.stateKey, this.state);
    }

    //DATA
    getProviders() {
        const { commands, actions } = this.state;

        actions.isLoadingProviders = true;
        commands.provider.getProviders((r) => this.providersSuccessCallback(r));

        this.setState({
            actions: actions
        });
    }

    providersSuccessCallback(result) {
        const { dependencies, actions } = this.state;

        dependencies.providers = result;
        actions.isLoadingProviders = false;

        this.setState({
            dependencies: dependencies,
            actions: actions
        });
    }

    getProviderLines(provider) {
        const { commands, actions } = this.state;
        const providerName = undefined === provider || undefined === provider.name ? "" : provider.name;

        actions.isLoadingLines = true;
        commands.provider.getProviderLines(providerName, (r) => this.providerLinesSuccessCallback(r))

        this.setState({
            actions: actions
        });
    }

    providerLinesSuccessCallback(result) {
        const { dependencies, render, actions } = this.state;

        dependencies.lines = result;
        render.lineDropdown = true;
        render.directionDropdown = false;
        render.dayTypeDropdown = false;
        actions.isLoadingLines = false;

        this.setState({
            dependencies: dependencies,
            render: render,
            actions: actions
        });
    }

    getDayTypes() {
        const { form, commands, actions } = this.state;

        actions.isLoadingDayTypes = true;
        commands.schedule.getScheduleByLineGroupByDayType(form.line.id, (r) => this.dayTypesSuccessCallback(r));

        this.setState({
            actions: actions
        });
    }

    dayTypesSuccessCallback(result) {
        const { dependencies, render, actions, form } = this.state;

        dependencies.dayTypes = result;
        render.dayTypeDropdown = true;
        actions.isLoadingDayTypes = false;
        if (result.length === 1) {
            form.dayType = result[0];
        }

        this.setState({
            dependencies: dependencies,
            render: render,
            actions: actions,
            form: form
        }, () => {
            if (result.length === 1) {
                this.getTrips();
            }
        });
    }

    getTrips() {
        const { commands, form, actions } = this.state;

        actions.isLoadingInfo = true;
        commands.trip.getTripsByLineAndDayType(
            form.line.id, form.direction, form.dayType.schedules.join(), (r) => this.getTripsSuccessCallback(r));

        this.setState({
            actions: actions
        });
    }

    getTripsSuccessCallback(result) {
        const { data, actions } = this.state;

        data.scheduleInfo = result;
        actions.isLoadingInfo = false;

        this.setState({
            data: data,
            actions: actions
        });
    }

    getLineScheduleDocument() {
        const { form } = this.state;

        if (undefined === form.line) {
            return;
        }

        const { commands } = this.state;

        commands.document.getLineScheduleDocument(
            form.line.id,
            (a) => this.getLineScheduleDocumentSucccessCallback(a),
            () => this.getLineScheduleDocumentErrorCallback()
        );
    }

    getLineScheduleDocumentSucccessCallback(document) {
        const { data, render } = this.state;

        data.document = document;
        render.disableDownloadButton = false;

        this.setState({
            render: render
        });
    }

    getLineScheduleDocumentErrorCallback() {
        const { data, render } = this.state;

        data.document = undefined;
        render.disableDownloadButton = true;

        this.setState({
            render: render
        });
    }

    downloadLineScheduleDocument() {
        const { data } = this.state;

        if (undefined === data.document) {
            return;
        }

        const { commands } = this.state;

        commands.document.downloadLineScheduleDocument(
            data.document.name,
            (a) => this.downloadLineScheduleDocumentSuccessCallback(a, data.document.name)
        );
    }

    downloadLineScheduleDocumentSuccessCallback(blob, name) {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(blob, name);
    }

    //---------

    //EVENTS
    onTripClick(tripId) {
        if (undefined === tripId || null === tripId || "" === tripId) {
            return;
        }

        this.props.history.push(`/trip-info/${tripId}`);
    }

    onDownloadDocumentClick() {
        this.downloadLineScheduleDocument();
    }

    //---------

    //FUNCTIONS
    setProvider(provider) {
        const { data, form, render } = this.state;

        data.scheduleInfo = { stops: [], trips: [], exceptions: [] };
        form.provider = provider;
        form.line = undefined;
        form.direction = undefined;
        form.dayType = undefined;
        render.lineDropdown = false;
        render.directionDropdown = false;
        render.dayTypeDropdown = false;

        this.setState({
            data: data,
            form: form,
            render: render
        }, () => {
            this.getProviderLines(provider);
        });
    }

    setLine(line) {
        const { data, form, render } = this.state;

        data.scheduleInfo = { stops: [], trips: [], exceptions: [] };
        data.document = undefined;
        form.line = line;
        form.direction = undefined;
        form.dayType = undefined;
        render.directionDropdown = true;
        render.dayTypeDropdown = false;

        this.setState({
            data: data,
            form: form,
            render: render
        }, () => {
            this.getLineScheduleDocument();
        });
    }

    setDirection(direction) {
        const { data, form, render } = this.state;

        data.scheduleInfo = { stops: [], trips: [], exceptions: [] };
        form.direction = direction;
        form.dayType = undefined;
        render.dayTypeDropdown = false;

        this.setState({
            data: data,
            form: form,
            render: render
        }, () => {
            this.getDayTypes();
        });
    }

    determineDirectionString(direction) {
        switch (direction) {
            case "G":
                return strings.directionGo;
            case "R":
                return strings.directionReturn;
            case "C":
                return strings.directionCircular;
            default:
                return "";
        }
    }

    setDayType(dayType) {
        const { data, form } = this.state;

        data.scheduleInfo = { stops: [], trips: [], exceptions: [] };
        form.dayType = dayType;

        this.setState({
            data: data,
            form: form
        }, () => {
            this.getTrips();
        });
    }

    //---------------

    //RENDER
    renderDropdowns() {
        return (
            <div>
                {this.renderLinesDropdown()}
                {this.renderDirectionsDropdown()}
                {this.renderDaytypesDropdown()}
            </div>
        );
    }

    renderLinesDropdown() {
        const { dependencies, form, render, actions } = this.state;

        if (actions.isLoadingLines) {
            return (
                <div className="width-100 text-align-center">
                    <Spinner animation="border" role="status" style={{ color: "#000" }} />
                </div>
            );
        }

        if (!render.lineDropdown) {
            return null;
        }

        return (
            <div className="schedules-lines-form margin-bottom-10">
                <div className="dropdown">
                    <Button variant="secondary" className="dropdown-toggle schedules-line-button" id="lineDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        {
                            form.line !== undefined ?
                                <span className="overflow-x-clip">
                                    <b>{form.line.code}</b> &nbsp; {form.line.goName}
                                </span>
                                :
                                <span className="text-color-placeholder overflow-x-clip">{strings.line}</span>
                        }
                    </Button>
                    <ul className="dropdown-menu schedules-line-dropdown-list" aria-labelledby="lineDropdown">
                        {
                            dependencies.lines.map(line =>
                                <li key={`${line.code}-${line.goName}`} onClick={() => this.setLine(line)}>
                                    <span className="dropdown-item">
                                        <b>{line.code}</b>&nbsp;{line.goName}
                                    </span>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        );
    }

    renderDirectionsDropdown() {
        const { form, render } = this.state;

        if (!render.directionDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-direction-button" id="directionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        form.direction !== undefined ?
                            <span className="overflow-x-clip">{this.determineDirectionString(form.direction)}</span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.direction}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-direction-dropdown-list" aria-labelledby="directionDropdown">
                    {
                        undefined !== form.line.directions ?
                            form.line.directions.map(direction =>
                                <li key={direction} onClick={() => this.setDirection(direction)}>
                                    <span className="dropdown-item">
                                        {this.determineDirectionString(direction)}
                                    </span>
                                </li>
                            )
                            :
                            null
                    }
                </ul>
            </div>
        );
    }

    renderDaytypesDropdown() {
        const { dependencies, form, render, actions } = this.state;

        if (actions.isLoadingDayTypes) {
            return (
                <div className="width-100 text-align-center">
                    <Spinner animation="border" role="status" style={{ color: "#000" }} />
                </div>
            );
        }

        if (!render.dayTypeDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-daytype-button" id="daytypeDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        form.dayType !== undefined ?
                            <span className="overflow-x-clip">{form.dayType.name}</span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.daytype}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-daytype-dropdown-list" aria-labelledby="daytypeDropdown">
                    {
                        dependencies.dayTypes.map(dayType =>
                            <li key={dayType.name} onClick={() => this.setDayType(dayType)}>
                                <span className="dropdown-item">
                                    {dayType.name}
                                </span>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }

    renderExceptionList(scheduleInfo) {
        return (
            <div className="schedules-schedule-exception-info-list">
                {
                    scheduleInfo.exceptions.map(exception =>
                        <div key={exception}>
                            {exception}
                        </div>
                    )
                }
            </div>
        );
    }

    renderScheduleInfoExceptions(scheduleInfo) {
        if (0 === scheduleInfo.exceptions.length) {
            return null;
        }

        return (
            <div className="schedules-schedule-exception-info">
                &nbsp;
                {this.renderExceptionList(scheduleInfo)}
            </div>
        );
    }

    renderScheduleInfo() {
        const { data, actions } = this.state;

        if (actions.isLoadingInfo) {
            return (
                <div className="width-100 text-align-center">
                    <Spinner animation="border" role="status" style={{ color: "#000" }} />
                </div>
            );
        }

        if(!Array.isArray(data.scheduleInfo.stops) || !Array.isArray(data.scheduleInfo.trips)) {
            return (
                <div className="width-100 text-align-center">
                    <span>{strings.noInfoToShow}</span>
                </div>
            );
        }

        const hasExceptions = Array.isArray(data.scheduleInfo.exceptions) && 0 < data.scheduleInfo.exceptions.length;

        return (
            <div className="schedules-schedule-info">
                <div className="schedules-schedule-trip-info">
                    {this.renderStopsList(data.scheduleInfo, hasExceptions)}
                    {this.renderTripList(data.scheduleInfo, hasExceptions)}
                </div>

                {this.renderScheduleInfoExceptions(data.scheduleInfo)}
            </div>
        );
    }

    renderStopsList(scheduleInfo, hasExceptions) {
        return (
            <div className="schedules-schedule-stop-info">
                {hasExceptions ? <div>&nbsp;</div> : null}
                {
                    scheduleInfo.stops.map(stop =>
                        <div key={`${stop.name}-${stop.order}`} title={stop.name}>
                            {stop.name}
                        </div>
                    )
                }
            </div>
        );
    }

    renderTripList(scheduleInfo, hasExceptions) {
        return (
            <div className="schedules-schedule-passing-info">
                {
                    scheduleInfo.trips.map((trip, index) => {
                        const firstPassing = trip[0];
                        let tripId = undefined;
                        if ("object" === typeof firstPassing) {
                            tripId = firstPassing.tripId;
                        }

                        return (
                            <div key={`${trip.stopId}-${index}`} className="schedules-schedule-passing-info-list" onClick={() => this.onTripClick(tripId)}>
                                {hasExceptions ? this.renderTripExceptions(trip) : null}
                                {this.renderTripPassings(trip, scheduleInfo.stops)}
                            </div>
                        );
                    })
                }
            </div >
        );
    }

    renderTripExceptions(trip) {
        const exceptions = trip
            .filter(passing => Array.isArray(passing.exceptions) && 0 < passing.exceptions.length)
            .reduce((output, passing) => {
                passing.exceptions.forEach(exception => {
                    if (!output.includes(exception)) {
                        output.push(exception);
                    }
                });
                
                return output;
            }, []).sort((a, b) => a.localeCompare(b)).join(", ");

        return (
            <div className="white-space-no-wrap">
                {"" === exceptions ? <span>&nbsp;</span> : exceptions.toUpperCase()}
            </div>
        );
    }

    renderTripPassings(trip, stops) {
        let isFirstPassing = true;

        return stops.map(stop => {
            const passing = trip.find(passing => passing.stopId === stop.id && passing.stopOrder === stop.order);
            if (undefined === passing) {
                return (
                    <div key={`undefined-time-${trip.order}-${stop.id}-${stop.order}`} className="schedules-trip-passing-list-empty-value">
                        --:--
                    </div>
                );
            }

            const output = (
                <div key={`time-${trip.order}-${stop.id}-${stop.order}-${passing.time}`} className={`schedules-trip-passing-list-value ${isFirstPassing ? "first-passing" : ""}`}>
                    {passing.time}{passing.exceptionIdentifier}
                </div>
            );
            isFirstPassing = false;

            return output;
        });
    }

    render() {
        return (
            <div className="schedules-panel">
                {this.renderDropdowns()}
                {this.renderScheduleInfo()}
            </div>
        );
    }
}
