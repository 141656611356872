import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import 'leaflet/dist/leaflet.css';
import { Container, Col, Row, Spinner, Button } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { TripInfoMap } from './TripInfoMap';
import { TripCommand } from '../commands/TripCommand';


export class TripInfo extends Component {
    static contextTypes = {
        getLogo: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            provider: "SMTUC",
            commands: {
                trips: new TripCommand()
            },
            data: {
                trip: undefined,
                pathStop: undefined
            },
            display: {
                info: true,
                map: true
            },
            actions: {
                isLoading: true
            }
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        this.getTrip(params.tripId);

        //Set function to handle when window is resized
        window.onresize = () => this.resize();

        //Call resize function to check the current width, otherwise resizing the window is the only trigger;  
        this.resize();
    }

    componentWillUnmount() {
        window.onresize = () => { };
    }

    async resize() {
        if (window.innerWidth > 767) {
            const { display } = this.state;

            display.info = true;
            display.map = true;

            this.setState({
                display: display
            });
        } else {
            let button = document.getElementById("trip-info-show-stops-button");

            /*
             * If the button is null, it's because this is the initial check and the button hasn't been rendered yet. 
             * Since we are not retrieving async info from anywhere, a possible workaround is to wait a few seconds (0.5s as of now) and try again.
             */
            if (null === button) {
                await new Promise(r => setTimeout(r, 500));
                this.resize();
            } else {
                button.click();
            }
        }
    }

    //--------------

    getTrip(tripId) {
        const { commands, actions } = this.state;

        actions.isLoading = true;
        commands.trips.getTrip(
            tripId,
            (a) => this.successCallback(a),
            () => this.errorCallback()
        );

        this.setState({
            actions: actions
        });
    }

    successCallback(result) {
        const { data, actions } = this.state;

        data.trip = result;
        data.pathStop = undefined;

        actions.isLoading = false;

        this.setState({
            data: data,
            actions: actions
        });
    }

    errorCallback() {
        const { data, actions } = this.state;

        data.trip = undefined;
        data.pathStop = undefined;

        actions.isLoading = false;

        this.setState({
            data: data,
            actions: actions
        });
    }

    //--------------

    //HELPER FUNCTIONS
    onReturn() {
        this.props.history.push("/schedules");
    }

    onSelectedPathStop(place) {
        const { data } = this.state;

        data.pathStop = place;

        this.setState({
            data: data
        });
    }

    displayInfo() {
        const { display } = this.state;

        display.info = true;
        display.map = false;

        this.setState({
            display: display
        });
    }

    displayMap() {
        const { display } = this.state;

        display.info = false;
        display.map = true;

        this.setState({
            display: display
        });
    }

    //--------------

    renderProviderLogo(provider) {
        const { getLogo } = this.context;
        const imgUrl = getLogo(provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img height="40px" src={imgUrl} alt={`${provider}`} />
        );
    }

    renderInfo() {
        const { data, display } = this.state;

        if (display.info && display.map) {
            return (
                <Row>
                    <Col sm={5} className="trip-info-container-trip-col">
                        {this.renderTripHeader(data.trip)}
                        {this.renderTrip(data.trip)}
                    </Col>
                    <Col sm={7} className="trip-info-container-map-col">
                        <TripInfoMap trip={data.trip} pathStop={data.pathStop} makeTooltipsPermanent={true} />
                    </Col>
                </Row>
            );
        } else {
            if (display.info) {
                return (
                    <Row>
                        <Col sm={12} className="trip-info-container-trip-col">
                            {this.renderTripHeader(data.trip)}
                            {this.renderTrip(data.trip)}
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row>
                        <Col sm={12} className="trip-info-container-map-col">
                            {this.renderTripHeader(data.trip)}
                            <TripInfoMap trip={data.trip} pathStop={data.pathStop} makeTooltipsPermanent={true} />
                        </Col>
                    </Row>
                );
            }
        }
    }

    renderTripHeader(trip) {
        const { actions } = this.state;

        if (actions.isLoading) {
            return (
                <div className="trip-info-header">
                    <div>
                        <Button className="trip-info-back-button" variant="light" onClick={() => this.onReturn()}>
                            <div className={"icon-redo"} />
                        </Button>
                    </div>
                    <div className="width-100 text-align-center">
                        <Spinner animation="border" role="status" style={{ color: "#000" }} />
                    </div>
                </div>
            );
        }

        const { display } = this.state;

        if (undefined === trip || 0 === trip.passings.length) {
            return (
                <div className="trip-info-header">
                    <div>
                        <Button className="trip-info-back-button" variant="light" onClick={() => this.onReturn()}>
                            <div className={"icon-redo"} />
                        </Button>
                    </div>
                    <div className="trip-info-panel-header text-align-center">
                        <span>{strings.noInfoToShow}</span>
                    </div>
                </div>
            );
        }

        const { provider } = this.state;
        const firstPassing = trip.passings[0];

        return (
            <div className="trip-info-header">
                <div className="trip-info-header-toolbar">
                    <Button className="trip-info-back-button" variant="light" onClick={() => this.onReturn()}>
                        <div className={"icon-redo"} />
                    </Button>
                    <div className="trip-info-small-screen-tabs">
                        <Button id="trip-info-show-stops-button" className={`trip-info-small-screen-tabs-link-button ${display.info ? "yellow-text" : ""}`} variant="link" onClick={() => this.displayInfo()}>
                            {strings.showStops}
                        </Button>
                        <span className="yellow-text">|</span>
                        <Button id="trip-info-show-map-button" className={`trip-info-small-screen-tabs-link-button ${display.map ? "yellow-text" : ""}`} variant="link" onClick={() => this.displayMap()}>
                            {strings.showMap}
                        </Button>
                    </div>
                </div>

                <div className="trip-info-panel-header">
                    <Row>
                        <Col sm={12}>
                            <div className="trip-info-panel-header-provider-info">
                                {this.renderProviderLogo(provider)}
                                <div className="trip-info-panel-header-trip-info">
                                    <div><b>{firstPassing.lineCode}</b></div>
                                    <div>{firstPassing.name} &gt; {trip.passings[trip.passings.length - 1].name}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    renderTrip(trip) {
        if (undefined === trip || 0 === trip.passings.length) {
            return null;
        }

        return (
            <div className="trip-info-panel">
                <span className="trip-info-stops-title">{strings.lineInfoStopsTitle}</span>
                <Container fluid className="trip-info-passings-list">
                    {
                        trip.passings.map((passing, index) =>
                            <div key={`trip-info-passing-${index}`} className="trip-info-passing-row" onClick={() => this.onSelectedPathStop(passing)}>
                                <div className="trip-info-passing-icon">
                                    <img src="icons/lineInfoMarker.png" alt="" />
                                </div>
                                <div className="trip-info-passing-stop">
                                    <div className="d-inline-block text-truncate">{passing.name} ({passing.stopCode})</div>
                                </div>
                                <div className="trip-info-passing-time">
                                    {passing.formattedTimeStamp}
                                </div>
                            </div>
                        )
                    }
                </Container>
            </div>
        );
    }

    render() {
        return (
            <Container fluid className="trip-info-container">
                {this.renderInfo()}
            </Container>
        );
    }
}
