import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        nextDepartures: "Next Departures",
        planRoute: "Plan Route",
        schedules: "Schedules",
        about: "About",
        searchPlaceholder: "Select a stop",
        originStopPlaceHolder: "Departure",
        destinationStopPlaceHolder: "Arrival",
        plannedRoutes: "Routes planned",
        planRouteTimeFilterSelectNow: "Now",
        planRouteTimeFilterSelectStartDate: "Select departure date",
        planRouteTimeFilterSelectedStartDate: "Departure at",
        planRouteTimeFilterSelectEndDate: "Select arrival date",
        planRouteTimeFilterSelectedEndDate: "Arrival at",
        lineInfoStopsTitle: "Stops",
        lineInfoMoreScheds: "more schedules",
        showStops: "Show stops",
        showSchedule: "Show schedule",
        showTrip: "Show route",
        showMap: "Show map",
        moreSchedsTitle: "Schedules",
        noPassingsToShow: "No passings to show",
        noTripsToShow: "No trips to show",
        noInfoToShow: "No information to show",
        selectDate: "Select date",
        provider: "Provider",
        line: "Line",
        direction: "Direction",
        directionGo: "Go",
        directionReturn: "Return",
        directionCircular: "Circular",
        daytype: "Day Type",
        locale: "en",
        hoursFilter: "Filter by hours",
        initialHourFilter: "Start time",
        finalHourFilter: "End time",
        filter: "Filter",
        close: "Close",
        done: "Done",
        copyright: "Copyright 2021 OPT",
        allRightsReserved: "All rights reserved",
        privacyPolicy: "Terms and Conditions & Privacy Policy",
        notFoundTitle: "404 - Page not found",
        notFoundDescription: "The page you are trying to access does not exist.",
        developedFor: "Developed for Guimabus - Transportes de Guimarães",
        contact: "Contact Us",
        name: "Name",
        email: "Email",
        emailWarning: "We'll never share your email with anyone else.",
        subject: "Subject",
        message: "Message",
        send: "Send",
        emailSuccess: "Message sent successfully.",
        emailError: "An error occurred, please try again later.",  
        pageNotFound: "Page not found",
        delayed: "Delayed",
        aheadSchedule: "Ahead of schedule"
    },
    pt: {
        nextDepartures: "Próximas Partidas",
        planRoute: "Planear Rota",
        schedules: "Horários",
        about: "Acerca de",
        searchPlaceholder: "Selecione uma paragem",
        originStopPlaceHolder: "Partida",
        destinationStopPlaceHolder: "Chegada",
        plannedRoutes: "Rotas calculadas",
        planRouteTimeFilterSelectNow: "Agora",
        planRouteTimeFilterSelectStartDate: "Escolher data de partida",
        planRouteTimeFilterSelectedStartDate: "Partida às",
        planRouteTimeFilterSelectEndDate: "Escolher data de chegada",
        planRouteTimeFilterSelectedEndDate: "Chegada às",
        lineInfoStopsTitle: "Paragens",
        lineInfoMoreScheds: "mais horários",
        showStops: "Ver paragens",
        showSchedule: "Ver horário",
        showTrip: "Ver viagem",
        showMap: "Ver mapa",
        moreSchedsTitle: "Horários",
        noPassingsToShow: "Sem tempos de passagem",
        noTripsToShow: "Sem viagens para mostrar",
        noInfoToShow: "Sem informação para mostrar",
        selectDate: "Seleccione uma data",
        provider: "Operador",
        line: "Linha",
        direction: "Sentido",
        directionGo: "Ida",
        directionReturn: "Volta",
        directionCircular: "Circular",
        daytype: "Tipo de Dia",
        locale: "pt",
        hoursFilter: "Filtrar por horas",
        initialHourFilter: "Hora inicial",
        finalHourFilter: "Hora final",
        filter: "Filtrar",
        close: "Fechar",
        done: "Concluído",
        copyright: "Copyright 2021 OPT",
        allRightsReserved: "Todos os direitos reservados",        
        privacyPolicy: "Termos e Condições & Política de Privacidade",
        notFoundTitle: "404 - Página não encontrada",
        notFoundDescription: "A página à qual está a tentar aceder não existe.",
        developedFor: "Desenvolvido para Guimabus - Transportes de Guimarães",
        contact: "Contacto",
        name: "Nome",
        email: "Email",
        emailWarning: "O seu endereço nunca será partilhado com mais nenhuma organização.",
        subject: "Assunto",
        message: "Mensagem",
        send: "Enviar",
        emailSuccess: "Mensagem enviada com sucesso.",
        emailError: "Ocorreu um erro, por favor tente mais tarde.", 
        pageNotFound: "Página não encontrada",
        delayed: "Atrasado",
        aheadSchedule: "Adiantado"
    }
});
