import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import './info.css';

export class PrivacyPolicy extends Component {
    render() {
        return (
            <Container fluid className="mb-5 px-md-6">

                <img src="img/logo-smtucH.png"></img>

                <Row className="mt-5">
                    <h1 className="paragraph">Política de Privacidade dos Serviços Municipalizados de Transportes Urbanos de Coimbra</h1>
                    <p className="paragraph">Fevereiro de 2020</p>
                </Row>

                <Row className="mt-5">
                    <h2 className="paragraph">Regulamento Geral Sobre a Proteção de Dados</h2>
                    <h3 className="paragraph">Âmbito da política de segurança da informação</h3>
                    <span className="indent">A presente Política de Privacidade e Proteção de Dados Pessoais tem por fim assumir o compromisso de respeito para com as regras da privacidade e proteção de dados pessoais constantes do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016 (RGPD), relativo à proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à circulação desses dados, bem como da legislação nacional aplicável, tomando as medidas técnicas e organizativas necessárias por forma a cumprir o RGPD e garantir que o tratamento dos dados pessoais é lícito, leal, transparente e limitado às finalidades autorizadas.</span>
                    <span className="indent">Os Serviços Municipalizados de Transportes Urbanos de Coimbra, doravante referidos por SMTUC, implementaram práticas de segurança e adotaram procedimentos com o objetivo de garantir a prossecução do interesse público nas áreas de intervenção dos serviços municipais.</span>
                    <span className="indent">A proteção dos cidadãos relativamente ao tratamento de dados pessoais é um direito fundamental, por isso, esclarece-se quais os dados pessoais que os SMTUC recolhem e para que finalidade são utilizados.</span>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Compromisso</h3>
                    <span className="indent">Os SMTUC asseguram que o tratamento dos dados pessoais é efetuado no âmbito da(s) finalidade(s) para os quais os mesmos foram recolhidos, ou para finalidades compatíveis com o(s) propósito(s) inicial(ais) para que foram recolhidos.</span>
                    <span className="indent">Os SMTUC assumem o compromisso de implementar uma cultura de minimização de dados, em que apenas se recolhem, utilizam e conservam os dados pessoais estritamente necessários ao desenvolvimento da sua atividade e à satisfação dos interesses dos cidadãos.</span>
                    <span className="indent">Os SMTUC comprometem-se a não proceder à divulgação ou partilha dos dados pessoais para fins comerciais ou de publicidade.</span>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Objetivo</h3>
                    <span className="indent">Os SMTUC pretendem assegurar um nível de proteção coerente e elevado das pessoas singulares, independentemente da sua nacionalidade ou do seu local de residência, relativamente ao tratamento dos seus dados pessoais.</span>
                    <span className="indent">A proteção deve aplicar-se ao tratamento de dados pessoais por meios automatizados, bem como ao tratamento manual, se os dados pessoais estiverem contidos ou se forem destinados a um sistema de ficheiros.</span>
                    <span className="indent">O tratamento de dados pessoais deve ser efetuado de forma lícita e equitativa.</span>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Objeto</h3>
                    <span className="indent">Os princípios da proteção de dados devem aplicar-se a qualquer informação relativa a uma pessoa singular identificada ou identificável. Os dados pessoais que tenham sido pseudonimizados, que possam ser atribuídos a uma pessoa singular mediante a utilização de informações suplementares, devem ser considerados informações sobre uma pessoa singular identificável.</span>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Recolha e tratamento de dados pessoais</h3>
                    <span className="indent">Os SMTUC recolhem e tratam os dados pessoais que sejam fornecidos voluntariamente pelo titular dos dados pessoais (doravante titular) através do preenchimento dos vários formulários existentes, tanto presencialmente como via website, telefone, correio postal ou eletrónico.</span>
                    <span className="indent">Os dados pessoais podem ser fornecidos através de requerimento, comunicação, queixa, participação e através dos canais de atendimento.</span>
                    <span className="indent">Qualquer informação que o titular forneça apenas será utilizada para a finalidade descrita e em cumprimento da legislação sobre proteção de dados.</span>
                    <span className="indent">O período durante o qual os dados são armazenados e conservados depende da finalidade para a qual a informação é tratada e da existência ou não de requisitos legais que obriguem a conservá-los por um período mínimo.</span>
                    <span className="indent">Na falta de exigência legal específica, os dados serão armazenados e conservados apenas pelo período mínimo necessário para as finalidades que motivaram a sua recolha ou o seu posterior tratamento, findo o qual os mesmos serão eliminados.</span>
                    <spspan className="indent" an>Alguns dados podem ser conservados para fins de arquivo de interesse público, investigação científica ou histórica, ou para fins estatísticos, sendo nesse caso utilizadas as medidas técnicas e organizativas adequadas à salvaguarda dos direitos e liberdades do titular dos dados.</spspan>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Responsabilidade</h3>
                    <span className="indent">Os SMTUC são responsáveis pelo tratamento dos dados pessoais recolhidos, bem como pela elaboração da presente Política de Privacidade.</span>

                    <span className="indent">O titular aceita que, não fornecendo todas as informações solicitadas, poderá não conseguir o correto funcionamento de algumas funcionalidades presentes no website, bem como comprometer um eventual tratamento informático, consulta ou contacto.</span>

                    <span className="indent">Os dados pessoais recolhidos são usados para dar resposta aos pedidos dos cidadãos, nomeadamente para a realização dos serviços prestados pelos SMTUC.</span>

                    <span className="indent">Os SMTUC salvaguardam que estes dados serão de acesso limitado às pessoas do universo municipal que tenham a necessidade de os conhecer.</span>

                    <span className="indent">Os dados pessoais dos menores de idade só serão recolhidos e tratados com o consentimento dos detentores das responsabilidades parentais ou encarregados de educação.</span>

                    <span className="indent">Em determinadas situações, os Dados Pessoais podem ter natureza mais sensível. O Regulamento Geral sobre a Proteção de Dados classifica-os como “categorias especiais de dados”.</span>

                    <span className="indent">O tratamento associado a essas categorias especiais de dados merece uma proteção acrescida no âmbito do RGPD e é sujeito a salvaguardas técnicas e organizativas específicas. Assim, a junção de documentação que incorpora categorias especiais de dados só deverá ser efetuada quando tais dados constem como documentos instrutórios dos modelos de requerimento e formulários disponibilizados e publicitados.</span>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Princípios aplicáveis ao tratamento de dados</h3>
                    <span className="indent">Os SMTUC comprometem-se a assegurar que os dados do titular por si tratados são:</span>
                    <ul>
                        <li>Objeto de um tratamento lícito, leal e transparente em relação ao titular;</li>
                        <li>Recolhidos para finalidades determinadas, explícitas e legítimas, não sendo tratados posteriormente de uma forma incompatível com essas finalidades;</li>
                        <li>Adequados, pertinentes e limitados ao que é necessário relativamente às finalidades para as quais são tratados;</li>
                        <li>Exatos e atualizados sempre que necessário;</li>
                        <li>Conservados de uma forma que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados;</li>
                        <li>Tratados de uma forma que garanta a sua segurança, incluindo a proteção contra o seu tratamento não autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental, sendo adotadas as medidas técnicas ou organizativas adequadas.</li>
                    </ul>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Direitos dos titulares dos dados</h3>
                    <h4 className="paragraph">I. Direito à informação</h4>
                    <span className="indent">Quando os dados são recolhidos junto do titular, este deve ser informado pelos serviços dos SMTUC sobre:</span>
                    <ul>
                        <li>A identidade e os contactos do responsável pelo tratamento;</li>
                        <li>As finalidades do tratamento a que os dados pessoais se destinam, bem como o fundamento legal para o tratamento, se aplicável;</li>
                        <li>Se o tratamento dos dados se basear em interesses legítimos dos SMTUC ou de um terceiro, a indicação de tais interesses;</li>
                        <li>Os destinatários ou categorias de destinatários dos dados pessoais, se aplicável;</li>
                        <li>O prazo de conservação dos dados pessoais;</li>
                        <li>O direito de solicitar aos SMTUC o acesso aos dados pessoais, a sua retificação, apagamento ou limitação;</li>
                        <li>O direito de se opor ao tratamento e o direito à portabilidade dos dados;</li>
                        <li>O direito de retirar o consentimento em qualquer altura, sem comprometer a licitude do tratamento efetuado com base no consentimento previamente dado, se o tratamento dos dados se basear no consentimento do titular;</li>
                        <li>A existência de obrigatoriedade legal ou contratual para a comunicação de dados pessoais, ou se constitui requisito necessário para a celebração de contrato, bem como se o titular está obrigado a fornecer os dados pessoais e as eventuais consequências de não fornecer esses dados;</li>
                        <li>A existência de decisões automatizadas, incluindo a definição de perfis, e informações relativas à lógica subjacente, bem como a importância e as consequências previstas de tal tratamento para o titular dos dados, se aplicável.</li>
                    </ul>
                    <span className="indent">A informação supra referida é prestada de forma concisa, transparente, inteligível e de fácil acesso, de forma gratuita e sem demora injustificada.</span>
                    <span className="indent">Caso os SMTUC pretendam proceder ao tratamento posterior dos dados do titular para fim que não seja aquele para o qual os dados foram recolhidos, antes desse tratamento, fornecerá ao titular informações sobre esse fim e quaisquer outras informações pertinentes, nos termos acima referidos.</span>

                    <h4 className="paragraph">II. Direito de acesso aos dados</h4>
                    <span className="indent">O titular tem o direito de obter confirmação de que os dados pessoais, que lhe digam respeito, são ou não objeto de tratamento. Em caso afirmativo, tem direito a aceder aos seus dados pessoais e às seguintes informações:</span>
                    <ul>
                        <li>As finalidades do tratamento dos dados;</li>
                        <li>As categorias dos dados pessoais em questão;</li>
                        <li>O prazo de conservação dos dados pessoais;</li>
                        <li>O procedimento para solicitar aos SMTUC a retificação ou limitação do tratamento dos dados pessoais;</li>
                        <li>As informações disponíveis sobre a origem dos dados, caso não tenham sido recolhidos junto do titular;</li>
                        <li>A existência de decisões automatizadas, incluindo a definição de perfis e informações relativas à lógica subjacente, bem como a importância e as consequências previstas de tal tratamento para o titular dos dados.</li>
                    </ul>
                    <span className="indent">Mediante solicitação, os SMTUC fornecerão ao titular, a título gratuito, uma cópia dos respetivos dados que se encontram em fase de tratamento. O fornecimento de outras cópias solicitadas pelo titular poderá acarretar custos administrativos.</span>

                    <h4 className="paragraph">III. Direito de retificação dos dados pessoais</h4>
                    <span className="indent">O titular tem o direito de solicitar, a qualquer momento, a retificação dos seus dados pessoais e o direito a completá-los, caso estejam incompletos.</span>
                    <span className="indent">Em caso de retificação dos dados, os SMTUC comunicam a cada destinatário a quem os dados tenham sido transmitidos a respetiva retificação, salvo se tal comunicação se revelar impossível ou implicar um esforço desproporcionado para a mesma.</span>
                    <span className="indent">Se o titular o solicitar, os SMTUC fornecem informações sobre os referidos destinatários.</span>

                    <h4 className="paragraph">IV. Direito à limitação do tratamento dos dados pessoais</h4>
                    <span className="indent">O titular tem o direito de obter a limitação do tratamento dos seus dados, quando:</span>
                    <ul>
                        <li>Contestar a exatidão dos dados pessoais, durante um período que permita aos SMTUC verificar a sua exatidão;</li>
                        <li>O tratamento for ilícito e o titular se opuser ao apagamento dos dados, solicitando, em contrapartida, a limitação da sua utilização;</li>
                        <li>Os SMTUC já não precisem dos dados do titular para fins de tratamento, mas esses dados sejam requeridos pelo titular para efeitos de declaração, exercício ou defesa de um direito em processo judicial;</li>
                        <li>O titular se tenha oposto ao tratamento, até se verificar que os motivos legítimos dos SMTUC prevalecem sobre os daquele.</li>
                    </ul>
                    

                    <h4 className="paragraph">V. Direito à portabilidade dos dados pessoais</h4>
                    <span className="indent">O titular tem o direito de receber os dados pessoais que lhe digam respeito e que tenha fornecido aos SMTUC, quando se verifiquem cumulativamente as seguintes situações:</span>
                    <ul>
                        <li>Os dados pessoais solicitados sejam fornecidos pelo titular e lhe digam respeito;</li>
                        <li>O tratamento seja realizado por meios automatizados, com base no consentimento prévio do titular ou na execução de um contrato no qual o titular dos dados é parte;</li>
                        <li>O exercício deste direito não prejudica os direitos e liberdades de terceiros.</li>
                    </ul>
                    <span className="indent">A informação deve, em regra, ser fornecida gratuitamente e em formato estruturado, de uso corrente e de leitura automática.</span>
                    <span className="indent">Quando o titular o solicitar e for tecnicamente viável, os SMTUC são obrigados a transmitir os dados diretamente para outra organização ou entidade.</span>
                    <br />

                    <h4 className="paragraph">VI. Direito de oposição ao tratamento</h4>
                    <span className="indent">O facto de o tratamento de dados pessoais ser lícito, fundado no exercício de funções de interesse público, de autoridade pública ou ainda por motivos de interesse legítimo dos SMTUC ou de terceiros, não obsta a que o titular tenha o direito de se opor ao tratamento dos dados pessoais que digam respeito à sua situação específica.</span>
                    <span className="indent">Nesta situação, os SMTUC cessarão o tratamento dos dados do titular, salvo se apresentar razões imperiosas e legítimas para esse tratamento que prevaleçam sobre os interesses, direitos e liberdades do titular, ou para efeitos de declaração, exercício ou defesa de um direito do Município em processo judicial.</span>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Exercício dos direitos pelo titular</h3>
                    <span className="indent">O direito de acesso, de retificação, de apagamento e de portabilidade, bem como o direito à oposição podem ser exercidos pelo titular mediante contacto com o SMTUC.</span>
                    <span className="indent">Os SMTUC darão resposta ao pedido do titular no prazo máximo de um mês a contar da receção do pedido, salvo em casos de especial complexidade, em que esse prazo pode ser prorrogado até dois meses.</span>
                    <span className="indent">Se os pedidos apresentados pelo titular forem manifestamente infundados ou excessivos, nomeadamente devido ao seu carácter repetitivo, Os SMTUC reservam-se no direito de cobrar custos administrativos ou recusar-se a dar seguimento ao pedido.</span>
                    <span className="indent">Quando o tratamento dos dados do titular for realizado pelos SMTUC com base no respetivo consentimento, o titular tem o direito de retirar esse consentimento a qualquer momento. A retirada do consentimento, todavia, não compromete a licitude do tratamento efetuado pelos SMTUC com base no consentimento previamente dado.</span>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Legislação aplicável</h3>
                    <span className="indent">Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016, relativo à proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à circulação desses dados.</span>
                    <span className="indent">Lei n.º 58/2019, de 8 de agosto, que assegura a execução, na ordem jurídica nacional, do Regulamento (UE) 2016/679 do Parlamento e do Conselho, de 27 de abril de 2016, relativo à proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados.</span>
                    <span className="indent">Lei nº 41/2004, de 18 de agosto, que transpõe para a ordem jurídica nacional a Diretiva n.º 2002/58/CE, do Parlamento Europeu e do Conselho, de 12 de julho, relativa ao tratamento de dados pessoais e à proteção da privacidade no sector das comunicações eletrónicas.</span>
                    <span className="indent">Lei n.º 67/98, de 26 de outubro (Lei de Proteção de Dados Pessoais).</span>
                </Row>

                <Row className="mt-5">
                    <h3>Alterações à Política de Privacidade</h3>
                    <span className="indent">Ocasionalmente, os SMTUC poderão atualizar a presente Política de Privacidade disponibilizando na sua página web sempre a versão mais recente.</span>
                </Row>

                <Row className="mt-5">
                    <h3 className="paragraph">Contacto para o Encarregado de Proteção de Dados (DPO):</h3>
                    <span className="indent">Email: <a href="mailto:dpo@cm-coimbra.pt">dpo@cm-coimbra.pt</a></span>
                    <span className="indent">Endereço Postal:</span>
                    <span className="indent">Encarregado de Proteção de Dados (DPO)</span>
                    <span className="indent">Câmara Municipal de Coimbra</span>
                    <span className="indent">Praça 8 de Maio</span>
                    <span className="indent">3000-300 Coimbra</span>
                </Row>

            </Container>
        );
    }
}