import { ProviderAPI } from '../../api/ProviderAPI';

export class ProviderCommand {
    getProviders(successCallback, errorCallback) {
        const apiResult = ProviderAPI.getProviders();
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getProviderLines(providerName, successCallback, errorCallback) {
        const apiResult = ProviderAPI.getProviderLines(providerName);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}