import L from 'leaflet';

const stopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/stopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const selectedStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedStopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const ticketOfficeIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/ticketOfficeIcon.png'),
    iconAnchor: new L.Point(25, 25),
    iconSize: new L.Point(50, 50)
});

const selectedTicketOfficeIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedTicketOfficeIcon.png'),
    iconAnchor: new L.Point(25, 25),
    iconSize: new L.Point(50, 50)
});

const lineInfoStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/lineInfoStopIcon.png'),
    iconAnchor: new L.Point(7.5, 7.5),
    iconSize: new L.Point(15, 15)
});

const lineInfoSelectedStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/lineInfoSelectedStopIcon.png'),
    iconAnchor: new L.Point(7.5, 7.5),
    iconSize: new L.Point(15, 15)
});

const routeOriginIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/routeOriginIcon.png'),
    iconAnchor: new L.Point(15, 15),
    iconSize: new L.Point(30, 30)
});

const routeDestinationIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/routeDestinationIcon.png'),
    iconAnchor: new L.Point(15, 15),
    iconSize: new L.Point(30, 30)
});

const vehicleIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/vehicle.png'),
    iconAnchor: new L.Point(20, 10),
    iconSize: new L.Point(40, 20)
});

export { stopIcon, selectedStopIcon, ticketOfficeIcon, selectedTicketOfficeIcon, lineInfoStopIcon, lineInfoSelectedStopIcon, routeOriginIcon, routeDestinationIcon, vehicleIcon };
