import React, { Component } from 'react';

export class Brand extends Component {

    render() {
        return (
            <div className="opt-brand">
                Powered by <a href="https://www.opt.pt/" target="_blank" rel="noopener noreferrer">OPT</a>
            </div>
        )
    }
}