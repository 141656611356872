import React, { Component } from 'react';
import { useMap, MapContainer, TileLayer, Pane, Polyline, Marker, Tooltip } from 'react-leaflet';
import { lineInfoStopIcon } from '../leaflet/StopIcons';
import 'leaflet/dist/leaflet.css';

export class TripInfoMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isToRecenter: true
        }
    }

    //HELPER FUNCTIONS
    getBounds(trip) {
        if (undefined === trip) {
            return [{ lat: 40.1, lng: -8.3 }, { lat: 40.4, lng: -8.6 }];
        }

        const bounds = trip.passings.reduce((output, value) => {
            const maxValues = output[0];
            const minValues = output[1];

            if (maxValues.lat < value.x) {
                maxValues.lat = value.x;
            }

            if (maxValues.lng < value.y) {
                maxValues.lng = value.y;
            }

            if (minValues.lat > value.x) {
                minValues.lat = value.x;
            }

            if (minValues.lng > value.y) {
                minValues.lng = value.y;
            }

            return output;
        }, [
            { lat: -90, lng: -180 },
            { lat: 90, lng: 180 }
        ]);

        console.log(bounds);
        return bounds;
    }

    /*This is used to change the Tooltip permanent prop, which is immutable and can't be changed after creating the Tooltip.
    * But since this is used in the key prop, it will trigger the re-render and subsquently change the permanent prop value.
    */
    isPermanent(passing) {
        const { pathStop } = this.props;
        return pathStop !== undefined && pathStop.stopId === passing.stopId
    }

    //------------------------

    renderTrip(trip) {
        if (undefined === trip || !Array.isArray(trip.passings)) {
            return null;
        }

        const positions = [];
        trip.passings.forEach(passing => positions.push([passing.x, passing.y]));

        return (
            <div key="polyLine-trip-info">
                <Polyline positions={positions} color="#ffd138" />
            </div>
        );
    }

    renderPathStops(trip) {
        if (undefined === trip || !Array.isArray(trip.passings)) {
            return null;
        }

        return (
            <div>
                {
                    trip.passings.map((passing, idx) =>
                        <Marker
                            key={`marker-${idx}`}
                            position={[passing.x, passing.y]}
                            icon={lineInfoStopIcon}>
                            {this.renderTooltip(passing)}
                        </Marker>
                    )
                }
            </div>
        );
    }

    renderTooltip(passing) {
        const { makeTooltipsPermanent } = this.props;

        if (makeTooltipsPermanent) {
            return (
                <Tooltip key={`tooltip-${passing.stopCode}-${this.isPermanent(passing)}`} permanent={this.isPermanent(passing)}>
                    <span><b>{passing.name}</b> ({passing.stopCode})</span>
                </Tooltip>
            );
        }

        return (
            <Tooltip key={`tooltip-${passing.stopCode}`}>
                <span><b>{passing.name}</b> ({passing.stopCode})</span>
            </Tooltip>
        );
    }

    render() {
        const { trip } = this.props;
        const { isToRecenter } = this.state;
        return (
            <MapContainer className="map-container" bounds={this.getBounds(trip)} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
                <SetBounds bounds={this.getBounds(trip)} isToRecenter={isToRecenter} />
                <Pane>
                    {this.renderTrip(trip)}
                </Pane>
                <Pane>
                    {this.renderPathStops(trip)}
                </Pane>
            </MapContainer>
        );
    }
}

function SetBounds({ bounds, isToRecenter }) {
    const map = useMap();
    if (isToRecenter) {
        map.fitBounds(bounds);
    }
    return null;
}
