import React, { Component, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Schedules } from './components/schedules/Schedules';
import { PrivacyPolicy } from './components/info/PrivacyPolicy';
import { TripInfo } from './components/tripInfo/TripInfo';
import { NotFound } from './components/NotFound';
import { LogoCommand } from '../src/components/commands/LogoCommand';
import './custom.css';

export default class App extends Component {
    static childContextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func,
        getMapCenter: PropTypes.func,
        setMapCenter: PropTypes.func,
        recenter: PropTypes.func
    };

    static displayName = App.name;

    constructor(props) {
        super(props);

        this.nextDeparturesRef = createRef();

        this.state = {
            states: {
                lineInfo: undefined,
                schedules: undefined,
            },
            commands: {
                logos: new LogoCommand()
            },
            logos: {}
        };
    }

    componentDidMount() {
        //this.getLogos();
    }

    //LOGOS
    getLogos() {
        const { commands } = this.state;
        commands.logos.getLogos((r) => this.getLogosSuccessCallback(r));
    }

    getLogosSuccessCallback(result) {
        this.setState({
            logos: result
        });
    }
    //----------------

    getChildContext() {
        return {
            getState: (a) => this.getComponentState(a),
            setState: (a, b) => this.setComponentState(a, b),
            getLogo: (provider) => this.getLogo(provider)
        };
    }

    getComponentState(which) {
        const { states } = this.state;
        return states[which];
    }

    setComponentState(which, newState) {
        const { states } = this.state;

        states[which] = newState;

        this.setState({
            states: states
        });
    }

    getLogo(provider) {
        const { logos } = this.state;
        if (undefined !== logos[provider]) {
            return `https://coimbra.move-me.mobi/content/logos/${logos[provider]}`;
        }

        return null;
    }

    render() {
        const { waitForGeolocationCheck } = this.state;

        if (waitForGeolocationCheck) {
            return null;
        }

        return (
            <Layout>
                <Switch>
                    <Redirect exact from='/' to='/schedules' />
                    <Route path='/schedules' component={Schedules} />
                    <Route path='/privacy-policy' component={PrivacyPolicy} />
                    <Route path='/trip-info/:tripId' component={TripInfo} />
                    <Route path='*' component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}
